<template>
  <div class="container kuan">
    <div class="main">
      <yanzheng />
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>
      <div style="height:10px"></div>
      <section class="ky_flex">
        <textarea class="textarea"></textarea>
        <div class="atten">
          重要提示：
          <p class="atten_flex">
            <span class="xuhao">1.</span>
            <span>本公司推出“快译”是为了方便客户进行投注，为了避免给自己带来不必要的损失，敬请各位客户务必检仔细查确认后再下注！ <span class="red">公司一切以下注明细为准</span> ，如有不便，希望各位会员谅解</span>
          </p>
          <p class="atten_flex">
            <span class="xuhao">2.</span>
            <span class="red">快译规则说明</span>
          </p>
          <p class="atten_flex">
            <span class="xuhao">3.</span>
            <span>每条规则中会去掉重复的号码</span>
          </p>
        </div>
      </section>
      <div class="ky_btn">
        <span class="button">生成号码明细</span>

      </div>

    </div>

  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import mixins from "../mixins/mainMixins";
import yanzheng from "../yanzheng.vue";
export default {
  name: "kuaiyi",
  props: [""],
  mixins: [mixins],
  data() {
    return {
      ltArr: [],
      title: "",
      titleId: "",
      titleNum: "",
      titleOdds: "",
      numArr1: [
        { label: "01", selected: false, number: 1, disable: false },
        { label: "02", selected: false, number: 2, disable: false },
        { label: "03", selected: false, number: 3, disable: false },
        { label: "04", selected: false, number: 4, disable: false },
        { label: "05", selected: false, number: 5, disable: false },
        { label: "06", selected: false, number: 6, disable: false },
        { label: "07", selected: false, number: 7, disable: false },
        { label: "08", selected: false, number: 8, disable: false },
        { label: "09", selected: false, number: 9, disable: false },
        { label: "10", selected: false, number: 10, disable: false },
        { label: "11", selected: false, number: 11, disable: false },
      ],
      numArr2: [
        { label: "01", selected: false, number: 1, disable: false },
        { label: "02", selected: false, number: 2, disable: false },
        { label: "03", selected: false, number: 3, disable: false },
        { label: "04", selected: false, number: 4, disable: false },
        { label: "05", selected: false, number: 5, disable: false },
        { label: "06", selected: false, number: 6, disable: false },
        { label: "07", selected: false, number: 7, disable: false },
        { label: "08", selected: false, number: 8, disable: false },
        { label: "09", selected: false, number: 9, disable: false },
        { label: "10", selected: false, number: 10, disable: false },
        { label: "11", selected: false, number: 11, disable: false },
      ],
      numArr3: [
        { label: "01", selected: false, number: 1, disable: false },
        { label: "02", selected: false, number: 2, disable: false },
        { label: "03", selected: false, number: 3, disable: false },
        { label: "04", selected: false, number: 4, disable: false },
        { label: "05", selected: false, number: 5, disable: false },
        { label: "06", selected: false, number: 6, disable: false },
        { label: "07", selected: false, number: 7, disable: false },
        { label: "08", selected: false, number: 8, disable: false },
        { label: "09", selected: false, number: 9, disable: false },
        { label: "10", selected: false, number: 10, disable: false },
        { label: "11", selected: false, number: 11, disable: false },
      ],
      rowsData: [{ title: "快选", arr: [] }],
      labelArr: ["前二直选", "前三直选"],
      bzArr: [2, 3],
      //  交叉碰
      jcRows: [[], []],
      jcpTotalNum: 0,
      jcpZuHeData: [], //交叉碰组合数据
    };
  },
  components: {
    yushe,
    topTimer,
    yanzheng,
  },
  watch: {
    jcRows: {
      deep: true,
      handler() {
        this.watchJCP();
      },
    },
    amount() {
      this.watchJCP();
    },
    // oddData: {
    //   immediate: true,
    //   deep: true,
    //   handler(resArr) {
    //     let arr = this.filterData(resArr, 1059, 1060, this.labelArr);
    //     arr.forEach((item, index) => {
    //       item.bzNum = this.bzArr[index];
    //     });
    //     this.rowsData[0].arr = arr;
    //     if (this.zxActive.ResultID) {
    //       this.getCurrentItem();
    //     } else {
    //       this.title = this.rowsData[0].arr[0].label;
    //       this.titleId = this.rowsData[0].arr[0].ResultID;
    //       this.titleNum = this.rowsData[0].arr[0].bzNum;
    //       this.titleOdds = this.rowsData[0].arr[0].recordOdds;

    //       // console.log(this.titleOdds, 222222);
    //       this.jcpZuHeData = [];
    //       this.jcpTotalNum = 0;
    //       this.jcRows = [];
    //       for (let index = 0; index < this.titleNum; index++) {
    //         this.jcRows.push([]);
    //       }
    //     }
    //   },
    // },
  },
  created() {},
  computed: {
    ...mapState(["amount", "quickSelectedData", "quick_c", "quick_b"]),
  },

  methods: {
    multiplyData(newArrData) {
      let len = newArrData.length;
      // 当数组长度大于等于2时
      if (len >= 2) {
        // 第一个数组的长度
        let len1 = newArrData[0].length;
        // 第二个数组的长度
        let len2 = newArrData[1].length;
        //  申明一个新数组,做数据暂存
        let items = new Array();
        // 申明新数组的索引
        let index = 0;
        // 2层嵌套循环,将组合放到新数组中
        for (var i = 0; i < len1; i++) {
          for (var j = 0; j < len2; j++) {
            if (items.length < index + 1) {
              items.push([]);
            }
            if (newArrData[0][i] instanceof Array) {
              items[index] = items[index].concat(
                newArrData[0][i].concat(newArrData[1][j])
              );
            } else {
              items[index] = items[index].concat([
                newArrData[0][i],
                newArrData[1][j],
              ]);
            }
            index++;
          }
        }
        // 将新组合的数组并到原数组中
        let newArr = new Array(len - 1);
        for (let i = 2; i < newArrData.length; i++) {
          newArr[i - 1] = newArrData[i];
        }
        newArr[0] = items;
        // 执行回调
        return this.multiplyData(newArr);
      } else {
        // console.log(newArrData[0]);
        return newArrData[0];
      }
    },
    watchJCP() {
      let b = this.jcRows.every((item) => item.length > 0);
      this.jcpTotalNum = 0;
      this.jcpZuHeData = [];
      if (b) {
        let newArr = this.multiplyData(this.jcRows);
        let AllLabelArr = [];
        this.jcpTotalNum = 0;
        newArr.forEach((item) => {
          this.jcpTotalNum++;
          let arr = [];
          item.forEach((ele) => {
            arr.push(ele.label);
          });
          AllLabelArr.push(arr);
        });
        this.jcpZuHeData = AllLabelArr;
        let xiazhu = [];
        AllLabelArr.forEach((item) => {
          let obj = {
            label: item.join(","),
            num: item.join(","),
            title: this.title,
            id: this.titleId,
            money: this.amount,
            Odds: this.titleOdds,
          };
          xiazhu.push(obj);
        });

        this.$store.commit("setXZList", xiazhu);
      } else {
        this.$store.commit("setXZList", []);
      }
    },
    setCurrentSelected(item, jcIndex, keyArr) {
      if (item.disable) {
        return;
      }
      item.selected = !item.selected;
      keyArr.forEach((num) => {
        let ele = this["numArr" + num].find((ele) => ele.number == item.number);
        ele.disable = item.selected;
      });
      if (item.selected) {
        this.jcRows[jcIndex].push(item);
      } else {
        let Index = this.jcRows[jcIndex].findIndex(
          (ele) => ele.number == item.number
        );
        this.jcRows[jcIndex].splice(Index, 1);
      }
      // console.log(this.jcRows);
      this.$forceUpdate();
    },
    setCurrentItem(item) {
      this.$store.commit("SETZHIXUANACTIVE", item);
      this.title = item.label;
      this.titleId = item.ResultID;
      this.titleOdds = item.Odds;
      this.titleNum = item.bzNum;
      this.jcpZuHeData = [];
      this.jcpTotalNum = 0;
      this.jcRows = [];
      for (let index = 0; index < this.titleNum; index++) {
        this.jcRows.push([]);
      }
      // console.log(this.jcRows);
      this.numArr1.forEach((item) => {
        item.selected = false;
        item.disable = false;
      });
      this.numArr2.forEach((item) => {
        item.selected = false;
        item.disable = false;
      });
      this.numArr3.forEach((item) => {
        item.selected = false;
        item.disable = false;
      });
    },
    getCurrentItem() {
      this.title = this.zxActive.label;
      this.titleId = this.zxActive.ResultID;
      this.titleOdds = this.zxActive.Odds;
      this.titleNum = this.zxActive.bzNum;
      this.jcpZuHeData = [];
      this.jcpTotalNum = 0;
      this.jcRows = [];
      for (let index = 0; index < this.titleNum; index++) {
        this.jcRows.push([]);
      }
      // console.log(this.jcRows);
      this.numArr1.forEach((item) => {
        item.selected = false;
        item.disable = false;
      });
      this.numArr2.forEach((item) => {
        item.selected = false;
        item.disable = false;
      });
      this.numArr3.forEach((item) => {
        item.selected = false;
        item.disable = false;
      });
    },
  },
};
</script>
<style  scoped>
.ky_btn .button {
  width: 120px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: #fff;
}
.skin-blue .ky_btn .button {
  background: #2161b3;
}
.skin-red .ky_btn .button {
  background: #c2455c;
}
.skin-brown .ky_btn .button {
  background: #a18361;
}
.ky_btn {
  margin: 10px auto;
  display: flex;
  justify-content: center;
}
.atten_flex .xuhao {
  display: inline-block;
  width: 20px;
  min-width: 20px;
  text-align: center;
}
.atten_flex {
  margin-bottom: 4px;
  display: flex;
}
.ky_flex .textarea {
  flex: 8;
  padding: 4px;
  border: 1px solid lightgray;
}
.ky_flex .atten {
  flex: 3;
  padding: 10px;
  box-sizing: border-box;
}
.ky_flex {
  padding: 5px;
  display: flex;
  width: 100%;
  height: 200px;
  box-sizing: border-box;
  border: 1px solid lightgray;
}
.main .cate span.selected .red {
  color: rgb(246, 1, 1);
  font-weight: bold;
}

.listmain .data ul li {
  flex: 1;
}
.listmain .data ul.custom_ul li .td_name {
  width: 100%;
}
</style>